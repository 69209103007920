var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-shadow",staticStyle:{"position":"absolute","top":"15px","left":"10px","pointer-events":"none"}},[_c('div',[_vm._v("视场:"),_c('span',[_vm._v(_vm._s(_vm.view.fov))])]),_c('div',[_vm._v("水平:"),_c('span',[_vm._v(_vm._s(_vm.view.hLookAt))])]),_c('div',[_vm._v("垂直:"),_c('span',[_vm._v(_vm._s(_vm.view.vLookAt))])])]),_c('div',{staticClass:"toolbar",staticStyle:{"position":"absolute","right":"5px","top":"15px","bottom":"15px"}},[_c('div',{staticClass:"text-shadow vtext"},[_vm._v(" 垂直视角限制 ")]),_c('vue-slider',{staticStyle:{"display":"inline-block","position":"absolute","right":"15px","top":"5px","bottom":"5px"},attrs:{"direction":"ttb","min":-90,"max":90,"enable-cross":false,"interval":0.1,"contained":true,"drag-on-click":true,"tooltip":'always'},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
var value = ref.value;
var index = ref.index;
var focus = ref.focus;
return [(index==1)?[_c('div',{staticClass:"custom-dot",on:{"dblclick":_vm.resetVLookAt}})]:_vm._e()]}},{key:"tooltip",fn:function(ref){
var value = ref.value;
var index = ref.index;
var focus = ref.focus;
return [(index==1)?[_c('div',{staticClass:"vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-left",staticStyle:{"background-color":"pink","border-color":"pink","color":"black"},on:{"dblclick":_vm.resetVLookAt}},[_c('span',{staticClass:"vue-slider-dot-tooltip-text"},[_vm._v(_vm._s(value))])])]:_vm._e()]}}]),model:{value:(_vm.vLookAtRange),callback:function ($$v) {_vm.vLookAtRange=$$v},expression:"vLookAtRange"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }